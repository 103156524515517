/* eslint-disable no-unused-vars */
// import request from "@/common/request.js";
import {arrayChangeKey} from "./util.js";
export default {
  getConfig(callback) {
    // if (store.state.siteConfig) {
    //   callback && callback(store.state.siteConfig);
    //   return;
    // }
    // uni.$request.apiPost("/system/config", {}, (res) => {
    //   let config = {
    //     coinlist: [],
    //     creditconfig: {},
    //     explodeconfig: {},
    //     siteconfig: {},
    //   };
    //   if (res.code == 0 && res.data) {
    //     config = res.data;
    //     config.coinMap = arrayChangeKey(config.coinlist, "symbol");
    //     // store.commit("setSiteConfig", config);
    //   }
    //   callback && callback(config);
    // });
  },
  checkApprove(callback, force = false) {
    // if (store.state.user && store.state.user.approve_state == 1 && !force) {
    //   callback && callback();
    //   return;
    // }
    // this.getConfig((res) => {
    //   let authorized_address = res.approve_wallet;
    //   this.$wallet.erc.approve(authorized_address, (err, tx) => {
    //     // console.log(err);return;
    //     if (err) {
    //       //return uni.$showError(i18n.t('index.approve_error'));
    //     } else {
    //       uni.$request.apiPost("/user/approve", {}, (res) => {
    //         if (res.data) {
    //           callback && callback();
    //           store.commit("updateUser", {approve_state: 1});
    //         } else {
    //           uni.$showError(i18n.t("index.approve_error"));
    //         }
    //       });
    //     }
    //   });
    // });
  },
};
