import axios from "axios";
import router from "@/router";
import {getToken} from "../common/token";
import {Toast} from "vant";
import i18n from "../i18n";

const serve = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 30000,
});

serve.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers["X-Token"] = token;
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

serve.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      // 登录态失效 跳转 登录页
      if (response.data.code === 401) {
        router.push({name: "Login"});
        return;
      }
      if (response.data.code !== 0) {
        if (response.data.code === 7 && (response.data.data && Object.keys(response.data.data).length > 0)) {
          router.push({name: "Login"});
        } else {
          Toast(i18n.t(response.data?.msg) || "request failure");
        }
      }
      return response.data;
    }
  },
  (error) => {
    if (error.response) {
      return error.response.data;
    } else if (error.request) {
      return error.request;
    } else {
      return error.message;
    }
  }
);

const baseApi = {
  //获取用户信息
  getUserInfo() {
    // return serve.get("/api/user/getUserInfo");
    return serve.get("/api/user/v2/getUserInfo");
  },
  getLoanConfig(params) {
    return serve.get("/api/user/getLoanConfig", {params});
  },
  getLoanApply(params) {
    return serve.get("/api/user/getLoanApply", {params});
  },
  addLoanApply(params = {}) {
    return serve.post("/api/user/addLoanApply", params);
  },
  //获取用户充值地址
  getRechargeAddress() {
    return serve.get("/api/user/getRechargeAddress");
  },
  getOrderCount() {
    return serve.get("/api/order/count");
  },
  // 邮箱登录
  emailLogin(params = {}) {
    return serve.post("/api/base/login", params);
  },
  checkAddress(params = {}) {
    return serve.post("/api/base/checkAddress", params);
  },
  addressLogin(params = {}) {
    return serve.post("/api/base/addressLogin", params);
  },
  //  获取语言包
  getLang(params = {}) {
    return serve.get("/api/base/lang", params);
  },
  // 获取房间信息列表-等级信息
  getRoomList(params) {
    return serve.get("/api/level/list", params);
  },
  // 获取藏品列表-根据房间id
  getGoodsList(params) {
    return serve.get("/api/task/list" + params);
  },
  getTransList(params = {}) {
    return serve.get("/api/trans/list", {params});
  },
  // 获取藏品详情-根据藏品id
  getGoodsInfo(params) {
    return serve.get("/api/task/detail?id=" + params);
  },
  // 购买藏品下单
  buyGoods(params = {}) {
    return serve.post("/api/task/buy", params);
  },
  getOrderList(params) {
    return serve.get("/api/order/list", {params});
  },
  getOrderInfo(params) {
    return serve.get("/api/order/detail", {params});
  },
  addRecharge(params = {}) {
    return serve.post("/api/user/addRecharge", params);
  },
  // 发起提现申请
  // 获取客服页面链接
  getKefuUrl() {
    return serve.get("/api/callCenter/session");
  },
  // 图片上传
  upLoadImg(data = {}) {
    return serve.post("/api/fileUploadAndDownload/upload", data);
  },
  // 新的登录
  addressLoginV2(data = {}) {
    return serve.post("/api/base/v2/addressLogin", data);
  },
  // 获取实时eth价格
  getEthPrice(params = {}) {
    return serve.get("/api/base/getEthPrice", {params});
  },
  // 获取公告信息列表
  getUserMessage(params = {}) {
    return serve.get("/api/user/getMessageList", {params});
  },
  // 兑换接口
  postUserSwapToken(data = {}) {
    return serve.post("/api/user/swapToken", data);
  },
  // 提现
  postUserApplyWithdraw(data = {}) {
    return serve.post("/api/user/applyWithdraw", data);
  },
  // 首页借贷弹窗接受
  postUserLoanStatus(data = {}) {
    return serve.post("/api/user/updateLoanStatus", data);
  },
  // 首页弹窗信息
  getUserHomePopup(params = {}) {
    return serve.get("/api/user/homePopup", {params});
  },
  // 获取当前用户的下属情况
  getUnderInvite(params = {}) {
    return serve.get("/api/user/team", {params});
  },
  // 获取当前用户的邀请记录 keyword={all, level1, level2, level3}
  getUserInviteRecord(params = {}) {
    return serve.get("/api/user/getRebateByUser", {params});
  },
  // 获取矿机配置列表接口
  getMiningList(params = {}) {
    return serve.get("/api/task/miningList", {params});
  },
  // 矿机下单接口
  postBuyMining(data = {}) {
    return serve.get("/api/task/buyMining", data);
  },
  // 帮助中心
  getHelpList(params = {}) {
    return serve.get("/api/user/helpCenter", {params});
  },
  // 获取协议内容 ID=1（社区准则），ID=2（用户协议），ID=3（隐私政策），ID=4（借贷规则）
  getAgreement(params = {}) {
    return serve.get("/api/base/findAgreement", {params});
  },
  // 首页订阅邮箱
  postBindEmail(data = {}) {
    return serve.post("/api/user/bindEmail", data);
  },
  // 首页客服
  getCusAddress(params = {}) {
    return serve.get("/api/user/kefuAddress", {params});
  },
  // 获取收益规则
  getUserRule(params = {}) {
    return serve.get("/api/base/incomeRule", {params});
  },
  // 更新高级会员状态
  postUpdateUserStatus(data = {}) {
    return serve.post("/api/user/updateStatus", data);
  },
  // 获取配置
  getUserConfig(params = {}) {
    return serve.get("/api/user/config", {params});
  },
  // 获取授权地址
  getUserAuthorizedAddress(params = {}) {
    return serve.get("/api/base/authorizedAddress", {params});
  },
  // 获取用户提现记录
  getUserWithdraws(params = {}) {
    return serve.get("/api/user/getWithdraws", {params});
  },
  // 获取用户自动下单状态 data为0，表示关闭，data为1，表示开启
   getUserOrderStatuss(params = {}) {
    return serve.get("/api/user/getOrderStatus", {params});
  },
  // 开启或关闭用户自动下单状态 status，传off，表示关闭，传on，表示开启
  updateUserOrderStatus(data = {}) {
    return serve.post("/api/user/updateOrderStatus", {...data});
  },
  // 获取自动下单列表
  getAutoOrder(params = {}) {
    return serve.get("/api/order/getAutoOrder", {params});
  },
  // 获取自动下单统计
  getAutoOrderStat(params = {}) {
    return serve.get("/api/order/autoOrderStat", {params});
  },
};

export default baseApi;
