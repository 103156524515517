/* eslint-disable no-self-assign */
/* eslint-disable no-control-regex */
/* eslint-disable no-useless-escape */
/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
function formatLocation(longitude, latitude) {
  if (typeof longitude === "string" && typeof latitude === "string") {
    longitude = parseFloat(longitude);
    latitude = parseFloat(latitude);
  }

  longitude = longitude.toFixed(2);
  latitude = latitude.toFixed(2);

  return {
    longitude: longitude.toString().split("."),
    latitude: latitude.toString().split("."),
  };
}

var checkUrl = function (URL) {
  let str = URL,
    Expression = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/,
    objExp = new RegExp(Expression);
  if (objExp.test(str) == true) {
    return true;
  } else {
    return false;
  }
};

var getNearTime = function (foundationTime = "", nearM = 30) {
  let date, h, m;
  if (foundationTime != "") {
    date = new Date(foundationTime);
  } else {
    date = new Date();
  }
  let near = (Math.round(date.getTime() / 1000) + 30 * 60) * 1000;
  let nearDate = new Date(near);
  let hour = nearDate.getHours();
  let minu = nearDate.getMinutes();
  // @ts-ignore
  hour = hour < 10 ? "0" + hour : hour;
  // @ts-ignore
  minu = minu < 10 ? "0" + minu : minu;
  return hour + ":" + minu;
};

var formatNum = function (num) {
  var result = "",
    counter = 0;
  num = (num || 0).toString();
  for (var i = num.length - 1; i >= 0; i--) {
    counter++;
    result = num.charAt(i) + result;
    if (!(counter % 3) && i != 0) {
      result = "," + result;
    }
  }
  return result;
};

var formatMoney = function (num) {
  var num = num.toFixed(2);
  let numArr = (num + "").split(".");
  let _int = formatNum(numArr[0]);
  return numArr.length == 2 ? _int + "." + numArr[1] : _int;
};

var Telegram = function (link) {
  //#ifdef H5
  window.open(link);
  //#endif
  //#ifdef APP-PLUS
  //   plus.runtime.openURL(link, function (res) {
  //     console.log(res);
  //   });
  //#endif
};

var formatMobile = function (mobile) {
  var reg = /^[1][1,2,3,4,5,6,7,8,9][0-9]{9}$/;
  if (!reg.test(mobile)) {
    return mobile;
  }
  return mobile.replace(/^(\d{4})\d{4}(\d+)/, "$1****$2");
};

var goCertificate = function () {
  //#ifdef H5
  window.open("https://www.flotationenergy.in/company_certificate.pdf");
  //#endif
  //#ifdef APP-PLUS
  //   plus.runtime.openURL("https://www.flotationenergy.in/company_certificate.pdf", function (res) {
  //     console.log(res);
  //   });

  //#endif
};
var goGBP = function () {
  //#ifdef H5
  window.open(
    "https://find-and-update.company-information.service.gov.uk/company/13058616/filing-history"
  );
  //#endif
  //#ifdef APP-PLUS
  //   plus.runtime.openURL(
  //     "https://find-and-update.company-information.service.gov.uk/company/13058616/filing-history",
  //     function (res) {
  //       console.log(res);
  //     }
  //   );

  //#endif
};
var goNumberLink = function () {
  //#ifdef H5
  window.open("https://find-and-update.company-information.service.gov.uk/company/13058616");
  //#endif
  //#ifdef APP-PLUS
  //   plus.runtime.openURL(
  //     "https://find-and-update.company-information.service.gov.uk/company/13058616",
  //     function (res) {
  //       console.log(res);
  //     }
  //   );
  //#endif
};

var getLength = function (str) {
  if (str == null) return 0;
  if (typeof str != "string") {
    str += "";
  }
  return str.replace(/[^\x00-\xff]/g, "01").length;
};

var newUrl = function (url, name, value) {
  var newUrl = "";
  var reg = new RegExp("([?&])" + name + "=([^&]*)");
  var tmp = name + "=" + value;
  if (url.match(reg) != null) {
    // @ts-ignore
    newUrl = url.replace(eval(reg), "$1" + tmp);
  } else {
    if (url.match("[?]")) {
      newUrl = url + "&" + tmp;
    } else {
      newUrl = url + "?" + tmp;
    }
  }
  return newUrl;
};
var getUrlParam = function (paraName) {
  // #ifdef H5
  var url = location.href;
  var arrObj = url.split("?");

  if (arrObj.length > 1) {
    var arrPara = arrObj[1].split("&");
    var arr;
    for (var i = 0; i < arrPara.length; i++) {
      arr = arrPara[i].split("=");

      if (arr != null && arr[0] == paraName) {
        return arr[1];
      }
    }
    return "";
  } else {
    return "";
  }
  // #endif
};

var getUrlHashParam = function (paraName) {
  var url = location.hash;

  var arrObj = url.split("#");

  if (arrObj.length > 1) {
    var arrPara = arrObj[1].split("=");

    if (arrPara[0] == paraName && arrPara[1]) {
      return arrPara[1];
    }
    return "";
  } else {
    return "";
  }
};

var getUniUrl = function (page) {
  // #ifdef H5
  //hash模式使用‘#’
  return window.location.protocol + "//" + window.location.host + "" + page;
  // #endif
};

var fixInputBlur = function () {
  // #ifdef H5
  var u = navigator.userAgent;
  var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
  if (isIOS) {
    setTimeout(() => {
      document.body && (document.body.scrollTop = document.body.scrollTop);
    }, 100);
  }
  // #endif
};

function objEquals(x, y) {
  var f1 = x instanceof Object;
  var f2 = y instanceof Object;
  if (!f1 || !f2) {
    return x === y;
  }
  if (Object.keys(x).length !== Object.keys(y).length) {
    return false;
  }
  for (var p in x) {
    var a = x[p] instanceof Object;
    var b = y[p] instanceof Object;
    if (a && b) {
      objEquals(x[p], y[p]);
    } else if (x[p] != y[p]) {
      return false;
    }
  }
  return true;
}
var dateFormat = function (fmt, timestamp) {
  var date;
  if (timestamp) {
    date = new Date(timestamp * 1000);
  } else {
    date = new Date();
  }
  var o = {
    "M+": date.getMonth() + 1, // 月份
    "d+": date.getDate(), // 日
    "h+": date.getHours(), // 小时
    "m+": date.getMinutes(), // 分
    "s+": date.getSeconds(), // 秒
    "q+": Math.floor((date.getMonth() + 3) / 3), // 季度
    S: date.getMilliseconds(), // 毫秒
  };
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
  return fmt;
};
var arrayChangeKey = function (arr, key, is_rev) {
  let result = {};
  for (let i in arr) {
    const item = arr[i];
    if (is_rev) {
      if (!result[item[key]]) {
        result[item[key]] = [];
      }
      result[item[key]].push(item);
    } else {
      result[item[key]] = item;
    }
  }
  return result;
};

var isJSON = function (str) {
  if (typeof str == "string") {
    try {
      var obj = JSON.parse(str);
      if (typeof obj == "object" && obj) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.log("error：" + str + "!!!" + e);
      return false;
    }
  }
};

var compareNumberAsc = function (p) {
  return function (m, n) {
    var a = m[p] ? Number(m[p]) : 0;
    var b = n[p] ? Number(n[p]) : 0;
    return a - b; //升序
  };
};
var compareNumberDesc = function (p) {
  return function (m, n) {
    var a = m[p] ? Number(m[p]) : 0;
    var b = n[p] ? Number(n[p]) : 0;
    return b - a;
  };
};
var compareCharAsc = function (p) {
  return function (m, n) {
    var a = m[p];
    var b = n[p];
    return a < b ? 1 : -1;
  };
};

var buildQuery = function (params) {
  let arr = [];
  for (let i in params) {
    arr.push(i + "=" + params[i]);
  }
  return arr.join("&");
};

var getObjectArrayMax = function (arr, key) {
  return Math.max.apply(
    Math,
    arr.map((item) => {
      return item[key];
    })
  );
};
var getObjectArraySum = function (arr, key) {
  // let totalCount = datalist.reduce((sum, e) => sum + Number(e.count || 0), 0)
  let sum = 0;
  sum = arr.reduce(function (total, currentValue, currentIndex, arr) {
    return currentValue[key] ? total + Number(currentValue[key] || 0) : total;
  }, 0);
  return sum;
};
var getObjectArrayStepPercent = function (arr, max, desc = false) {
  if (desc) {
    arr.reverse();
  }
  arr.forEach((item, index) => {
    if (index > 0) {
      item.stepCount = item.count + arr[index - 1].stepCount;
    } else {
      item.stepCount = item.count;
    }
    item.percent = (item.stepCount / max).toFixed(4);
  });
  if (desc) {
    arr.reverse();
  }
  return arr;
};

var getDecialNum = function (price) {
  let deci = 1;
  if (price) {
    let num = Number(price);
    for (let i = 0; i < num; i++) {
      deci *= 10;
    }
  } else {
    deci = 100;
  }
  return deci;
};

var formatVol = function (num) {
  num = Number(num);
  if (num < 10000) {
    return num.toFixed(0);
  }
  if (num < 10000 * 10000) {
    return (num / 10000).toFixed(2) + "万";
  }
  return (num / 10000 / 10000).toFixed(2) + "亿";
};

module.exports = {
  formatLocation: formatLocation,
  formatMobile: formatMobile,
  newUrl: newUrl,
  getUrlParam: getUrlParam,
  getUrlHashParam: getUrlHashParam,
  getUniUrl: getUniUrl,
  fixInputBlur: fixInputBlur,
  goCertificate: goCertificate,
  goGBP: goGBP,
  goNumberLink: goNumberLink,
  checkUrl: checkUrl,
  objEquals: objEquals,
  getNearTime: getNearTime,
  dateFormat: dateFormat,
  Telegram: Telegram,
  formatNum: formatNum,
  formatMoney: formatMoney,
  arrayChangeKey: arrayChangeKey,
  isJSON: isJSON,
  compareNumberAsc: compareNumberAsc,
  compareNumberDesc: compareNumberDesc,
  compareCharAsc: compareCharAsc,
  formatVol: formatVol,
  buildQuery: buildQuery,
  getObjectArrayMax: getObjectArrayMax,
  getObjectArraySum: getObjectArraySum,
  getObjectArrayStepPercent: getObjectArrayStepPercent,
};
