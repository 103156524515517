<template>
  <div class="tab-bar">
    <div
      class="tab-item"
      v-for="(item, index) in tabList"
      :key="item.name"
      @click="changeTab(index, item.url)"
    >
      <img class="icon" v-if="currentIndex === index" :src="item.hovimg" />
      <img class="icon" v-else :src="item.img" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      // currentIndex: 0,
      tabList: [
        {
          url: "/index",
          name: "发现",
          img: require("../assets/images/btn_bar_explore_01.webp"),
          hovimg: require("../assets/images/btn_bar_explore_02.webp"),
        },
        {
          url: "/room",
          name: "收藏",
          img: require("../assets/images/btn_bar_collection_01.webp"),
          hovimg: require("../assets/images/btn_bar_collection_02.webp"),
        },
        {
          url: "/mining",
          name: "矿机",
          img: require("../assets/images/btn_bar_trade_01.webp"),
          hovimg: require("../assets/images/btn_bar_trade_02.webp"),
        },
        {
          url: "/robot",
          name: "钱包",
          img: require("../assets/images/btn_bar_wallet_01.webp"),
          hovimg: require("../assets/images/btn_bar_wallet_02.webp"),
        },
        {
          url: "/mine",
          name: "我的",
          img: require("../assets/images/btn_bar_account_01.webp"),
          hovimg: require("../assets/images/btn_bar_account_02.webp"),
        },
      ],
    };
  },
  methods: {
    changeTab(index, url) {
      this.$emit("changeTab", index);
      this.$router.push(url);
    },
  },
};
</script>

<style lang="less">
.tab-bar {
  display: flex;
  background: #ffffff;
  box-shadow: 0px 2px 12px 0px rgba(3, 29, 79, 0.1);
  padding: 0.3rem 0.4rem;
  position: fixed;
  bottom: 0.5rem;
  left: 50%;
  border-radius: 0.54rem;
  transform: translateX(-50%);
  z-index: 10;
  .tab-item {
    padding: 0 0.26rem;
    img.icon {
      width: 0.6rem;
      height: 0.6rem;
    }
  }
}
</style>
