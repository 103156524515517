<template>
  <div id="app" @click="handleClick">
    <van-loading size="24px" type="spinner" vertical v-if="loading" style="padding-top: 50px"
      >loading...</van-loading
    >
    <div v-else>
      <GlobalHeader
        :showBack="showBack"
        v-show="showHeader"
        :isShow="invisible"
        :langList="langList"
        @is-show="handleIsShow"
      />
      <router-view />
    </div>
  </div>
</template>

<script>
import GlobalHeader from "@/components/GlobalHeader.vue";
export default {
  data() {
    return {
      loading: true,
      showHeader: true,
      showBack: false,
      invisible: 0,
      langList: [],
    };
  },
  components: {
    GlobalHeader,
  },
  created() {
    this.initLang();
    // 进入页面执行
    // 记录当前时间并转成时间戳
    const now = new Date().getTime();
    // 从缓存中获取用户上次退出的时间戳
    const lt = localStorage.getItem('leaveTime') || ''
    const leaveTime = parseInt(lt, 10);
    // 判断是否为刷新，两次间隔在5s内判定为刷新操作
    const refresh = (now - leaveTime) <= 5000;
    // 测试alert
    // alert(refresh ? '刷新' : '重新登陆');
    if (process.env.NODE_ENV !== "development") {
      if (refresh || !refresh) {
        const c_text = location.href.includes('?') ? location.href.split('?')[1] : ''
        const path = c_text ? `/login?${c_text}` : '/login'
        this.$router.replace(path)
      }
    }
    // 退出当前页面执行
    window.onunload = function(){ // ios 不支持 window.onbeforeunload()
      // 将退出时间存于localStorage中
      localStorage.setItem('leaveTime', `${new Date().getTime()}`);
    }
  },
  watch: {
    // 方法1
    $route(to) {
      //监听路由是否变化
      const noShowBackHeaderPage = ["Home", "Room", "Mining", "Robot", "Mine"];
      const excludeNameList = ["KF", "privacy", "Agreement", "Community", "Login"];
      if (excludeNameList.includes(to.name)) {
        //跳转到哪个页面
        this.showHeader = false;
      } else {
        this.showHeader = true;
      }
      if (noShowBackHeaderPage.includes(to.name)) {
        this.showBack = false;
      } else {
        this.showBack = true;
      }
    },
  },
  methods: {
    handleIsShow(v) {
      this.invisible = v;
    },
    handleClick() {
      this.invisible = 1;
    },
    initLang() {
      this.$http.getLang().then((res) => {
        const r_data = res.data.lang;
        const langList = Object.values(r_data);
        const langKeys = Object.keys(r_data);
        const langKeyArr = langList
          .map((v) => {
            // console.log(v);
            return Object.keys(v);
          })[0]
          .flat(Infinity);
        if (langKeyArr.includes("lang")) {
          const i = langKeyArr.findIndex((v) => v === "lang");
          langKeyArr.splice(i, 1);
          this.langList = langKeyArr;
        }
        const langObj = {};
        langKeyArr.forEach((v) => {
          langObj[v] = {};
        });

        const valArr = langList.map((v) => {
          const keys = Object.keys(langObj);
          return keys.map((vv) => {
            return [v[vv]];
          });
        });

        valArr.forEach((_, _i) => {
          _.forEach(([v], i) => {
            langObj[langKeyArr[i]][langKeys[_i]] = v;
          });
        });
        // console.log(langObj);
        Object.keys(langObj).forEach((v) => {
          this.$i18n.mergeLocaleMessage(v, langObj[v]);
        });
        this.loading = false;
        // 判断浏览器是否 有钱包插件 或者是 Dapp浏览器
        if (window && !window.ethereum) {
          this.$toast(this.$i18n.t("open_dapp_tips"));
        }
      });
    },
  },
};
</script>

<style lang="less">
@import "./assets/css/base.less";
#app {
  font-family: myReFont, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  max-width: 768px;
  margin: 0 auto;
}
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
