<template>
  <div
    @click="
      showMenu = false;
      showLang = false;
    "
  >
    <div class="global-header">
      <div class="left-box">
        <div class="back" @click.stop="back" v-if="showBack">
          <img src="../assets/images/arraw-left.png" alt="" />
        </div>
        <div class="avatar-box" @click.stop="toHome">
          <!-- <img v-if="!showBack" class="avatar" src="../assets/images/logo.png" /> -->
          <div v-if="!showBack">
            <van-image
              width="0.56rem"
              height="0.56rem"
              fit="cover"
              :src="sysConfig.appLogo" 
            />
          </div>
          <!-- <img class="logo" src="../assets/images/logo.svg" /> -->
          <!-- <div class="word f32"><span class="font-bold">Pie universe</span></div> -->
          <div class="word f32" style="margin-left: 5px;"><span class="font-bold">{{ sysConfig.appName }}</span></div>
        </div>
      </div>
      <div class="right-box">
        <van-badge :dot="!isRead">
          <div class="notice" @click="$router.push('/message')"></div>
        </van-badge>

        <!-- <div class="airdrop theme-word-color">Airdrop</div> -->
        <div class="airdrop theme-word-color"></div>
        <div class="menu" @click.stop="selectMenu"></div>
      </div>
    </div>
    <div class="fiexed-box" v-if="isFill"></div>
    <van-popup v-model="showMenu" :overlay="false" round position="right">
      <div class="popup-menu-list">
        <div class="menu-item" @click.stop="toHome">
          <img src="../assets/images/icon-home.svg" alt="" />
          <div class="word">{{ $i18n.t("gh_home") }}</div>
        </div>
        <div class="menu-item" @click.stop="selectLang">
          <img src="../assets/images/icon-lang.svg" alt="" />
          <div class="word">{{ $i18n.t("gl_language") }}</div>
        </div>
        <div class="menu-item" @click.stop="toKf">
          <img src="../assets/images/icon-kf.svg" alt="" />
          <div class="word">{{ $i18n.t("gl_customer_service") }}</div>
        </div>
      </div>
    </van-popup>
    <van-popup v-model="showLang" :overlay="true" position="right" style="width: 40%; height: 100%">
      <div class="popup-menu-list">
        <div
          class="menu-item"
          v-for="(item, index) in langArr"
          :key="index"
          @click="handleClick(item)"
        >
          <div class="word">{{ item.title }}</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  props: {
    isFill: {
      type: Boolean,
      default: false,
    },
    showBack: {
      type: Boolean,
      default: false,
    },
    isShow: {
      type: Number,
      default: 0,
    },
    langList: {
      type: Array,
      default: () => {
        return [
          {
            key: "en",
            title: "English",
          },
        ];
      },
    },
  },
  data() {
    return {
      showMenu: false,
      showLang: false,
    };
  },
  watch: {
    isShow(a) {
      if (a === 1) {
        this.showMenu = false;
        this.showLang = false;
      }
    },
  },
  computed: {
    langArr() {
      const lang = {
        zh: "中文",
        en: "English",
        kr: "한국어",
        th: "ไทย",
        vi: "Tiếng Việt",
        ja: "日本語",
        tr: "Türkçe",
        ru: "русский",
        fr: "Français",
      };
      // 泰语th，越南语vi，日语ja，土耳其语tr，俄语ru，法语fr
      return this.langList.map((v) => {
        return {key: v, title: lang[v]};
      });
    },
    sysConfig() {
      return this.$store.state.user.sysConfig
    },
    isRead() {
      return this.$store.state.user.isRead
    },
    userStoreInfo() {
      return this.$store.state.user.userStoreInfo
    },
    host() {
      return location.host
    }
  },
  created() {},
  mounted() {
    if (window.$crisp) {
      window.$crisp.push(["on", "chat:closed", () => {
        window.$crisp.push(["do", "chat:hide"])
      }])
    }
  },
  methods: {
    handleClick(item) {
      this.$i18n.locale = item.key;
    },
    back() {
      this.$router.back();
    },
    toHome() {
      this.showMenu = false;
      this.$router.push("/index");
    },
    toKf() {
      this.showMenu = !this.showMenu;
      if (['erpensix.com', 'trust-nft.info', "pryfloorprice.com"].includes(location.host)) {
        window.$crisp.push(["set", "user:nickname", [this.userStoreInfo.user.address]]);
        window.$crisp.push(["set", "user:phone", [this.userStoreInfo.user.ID]]);
        window.$crisp.push(["do", "chat:show"])
        window.$crisp.push(["do", "chat:toggle"])        
        return
      }
      if (['aipenseen.com', 'marketsea.vip', 'aipensix.com'].includes(location.host)) {
        window._MEIQIA('metadata', {
            name: this.userStoreInfo.user.address
        });
        window._MEIQIA('showPanel');
        return
      }
      this.$router.push("/kf");
    },
    selectMenu() {
      this.showMenu = !this.showMenu;
      this.showLang = false;
      this.$emit("is-show", 0);
    },
    selectLang() {
      this.showLang = true;
      this.showMenu = false;
    },
  },
};
</script>

<style lang="less" scoped>
.van-popup {
  top: 56px;
  transform: translate3d(0, 0, 0);
  box-shadow: -2px 4px 8px 0px rgba(0, 0, 0, 0.1);
}
.fiexed-box {
  width: 100%;
  height: 56px;
}
.global-header {
  width: 100%;
  height: 56px;
  // padding: 0.16rem 0;
  border: 1px solid #f5f5f5;
  background: rgba(255, 255, 255, 0.85);
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 3000;
  position: fixed;
  left: 0;
  top: 0;
  background-color: hsla(0,0%,100%,.7);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  align-items: center;
  .left-box {
    display: flex;
    padding-left: 0.3rem;
    .back {
      width: 0.4rem;
      height: 0.4rem;
      margin-top: 0.08rem;
      margin-right: 0.16rem;
      // line-height: 0.54rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-radius: 100%;
      background: linear-gradient(
        90deg,
        #00cdff 0%,
        #2993ff 24%,
        #458fff 49%,
        #52d3ba 86%,
        #63ff92 100%
      );
      img {
        width: 0.24rem;
        margin: 0 auto;
      }
    }
    .avatar-box {
      display: flex;
      .avatar {
        height: 0.56rem;
        margin-right: 5px;
      }
      .word {
        // height: 0.36rem;
        line-height: 0.56rem;
        //   transform: scale(0.8);
      }
    }
  }
  .right-box {
    display: flex;
    line-height: 0.4rem;
    margin-top: 0.08rem;
    padding-right: 0.3rem;
    .notice {
      width: 0.4rem;
      height: 0.4rem;
      background: url("../assets/images/icon-notice.svg");
      background-size: 100%;
    }
    .airdrop {
      font-size: 15px;
      line-height: 0.4rem;
      margin: 0 0.2rem 0 0.22rem;
      position: relative;
    }
    .airdrop::after {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background: linear-gradient(
        -90deg,
        #00cdff 0%,
        #2993ff 24%,
        #458fff 49%,
        #52d3ba 86%,
        #63ff92 100%
      );
      position: absolute;
    }
    .menu {
      width: 0.4rem;
      height: 0.4rem;
      background: url("../assets/images/icon-menu.svg");
      background-size: 100%;
    }
  }
}
.popup-menu-list {
  padding: 0.2rem 0.24rem;
  .menu-item {
    min-width: 2rem;
    display: flex;
    line-height: 0.48rem;
    margin-bottom: 0.3rem;
    padding-bottom: 0.2rem;
    border-bottom: 1px solid #eee;
    img {
      width: 0.32rem;
      height: 0.32rem;
      margin-top: 0.08rem;
      margin-right: 0.2rem;
    }
    .word {
      color: #333;
      font-size: 0.28rem;
    }
  }
  .menu-item:last-child {
    margin-bottom: 0;
  }
}
</style>
