import baseApi from "@/api";

export default {
    state: {
        token: '',
        userStoreInfo: {},
        currentETHPrice: 0,
        isRead: false,
        roomStatus: '',
        sysConfig : {
            appName: null, //APP名称
            appLogo: '', //APP LOGO
            minWithdrawAmount: null, //最小提现数量 (USDT)
            withdrawFee: null, //提现手续费
            withdrawTime: "00:00-23:59", // 处理提现时间
            withdrawTimeUtc: "00:00-23:59", //处理提现时间(UTC)
            ethExchageRate: null, //ETH兑换比例
            trxExchangeRate: null, //TRX兑换比例
            customerId: null, //客服ID
            customerKey: null, //客服KEY
            customerStatus: null, //第四方客服开关
            telegramNoticeId: null, //飞机通知ID
            h5Url: null, //H5_URL
            trainPayChannel: null, //直通车付款通道，开启，关闭；：on, 0ff
            homePopupStatus: null, //首页弹窗开关：，0ff
            homePopupContent: null, //首页弹窗内容：
            orderScoreAdd: null, //订单信用增加值
            orderScoreSub: null, //订单信用减少值
            homeEthAmount: null, //首页累计输出ETH
            homeUsdtAmount: null, //首页累计输出USDT
            homeMemberNum: null, //首页会员数
        }
    },
    mutations:{
        update(state, sysConfig){
            Object.assign(state.sysConfig, sysConfig)
        },
        modifyRead(state, read){
            state.isRead = read
        },
        modifyRoomStatus(state, status){
            state.roomStatus = status
        },
        updateUserStoreInfo(state, preload){
            state.userStoreInfo = preload
        },
        updateCurrentETHPrice(state, preload){
            state.currentETHPrice = preload
        },
        updateToken(state, preload){
            state.token = preload
        }
    },
    actions: {
        async getPrice({ commit }) {
            const now = new Date().getTime();
            const lt = sessionStorage.getItem('priceTime') || ''
            const priceTime = parseInt(lt, 10);
            const refresh = (now - priceTime) <= 7000;
            if (refresh) {
                return Promise.resolve()
            } else {
                const _res = await baseApi.getEthPrice()
                if (_res.code === 0) {
                    commit('updateCurrentETHPrice', _res.data.ethPrice.price)
                    sessionStorage.setItem('priceTime', `${new Date().getTime()}`);
                    return Promise.resolve()
                }
            }
        },
        async getInfo({ commit }) {
            const _res = await baseApi.getUserInfo()
            if (_res.code === 0) {
                commit('updateUserStoreInfo', _res.data)
                return Promise.resolve()
            }
        },
        async dispatchPriceUserInfo({ dispatch, state, commit }) {
            await dispatch('getPrice')
            await dispatch('getInfo')
            const calcFrozenEth = (state.userStoreInfo.frozen / state.userStoreInfo.currentETHPrice + state.userStoreInfo.unsettledETH).toFixed(6);
            commit('updateUserStoreInfo', {...state.userStoreInfo, calcFrozenEth})
        },
        async dispatchToken({ commit }, token) {
            commit('updateToken', token)
            return Promise.resolve()
        }
    }
}