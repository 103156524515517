// "1999-02-07T21:35:56.157Z"格式时间转换
function formatDate(originVal) {
  const dt = new Date(originVal);
  const y = dt.getFullYear();
  const m = (dt.getMonth() + 1 + "").padStart(2, "0");
  const d = (dt.getDate() + "").padStart(2, "0");
  const hh = (dt.getHours() + "").padStart(2, "0");
  const mm = (dt.getMinutes() + "").padStart(2, "0");
  const ss = (dt.getSeconds() + "").padStart(2, "0");
  return `${y}-${m}-${d} ${hh}:${mm}:${ss}`;
}
// "1641964865"格式时间转换
function formatProductDate(value) {
  const time = new Date(value * 1000);
  const Y = time.getFullYear();
  const M = (time.getMonth() + 1).toString().padStart(2, "0");
  const D = time.getDate().toString().padStart(2, "0");
  const h = time.getHours().toString().padStart(2, "0");
  const m = time.getMinutes().toString().padStart(2, "0");
  const s = time.getSeconds().toString().padStart(2, "0");
  return `${Y}-${M}-${D} ${h}:${m}:${s}`;
}
// "Wed Mar 29 10:06:14 CST 2023"格式时间转换
function formatCSTDate(date, format) {
  date = new Date(date);
  date.setHours(date.getHours() - 14);
  var o = {
    "M+": date.getMonth() + 1,
    "d+": date.getDate(),
    "H+": date.getHours(),
    "m+": date.getMinutes(),
    "s+": date.getSeconds(),
    "q+": Math.floor((date.getMonth() + 3) / 3),
    S: date.getMilliseconds(),
  };
  if (/(y+)/.test(format))
    format = format.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (var k in o)
    if (new RegExp("(" + k + ")").test(format))
      format = format.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
  return format;
}
//导出
export default {
  install(Vue) {
    Vue.filter("formatDate", formatDate);
    Vue.filter("formatProductDate", formatProductDate);
    Vue.prototype.$formatCSTDate = formatCSTDate;
  },
};
