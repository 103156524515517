import Vue from "vue";
import i18n from "./i18n";

import App from "./App.vue";
import router from "./router";
import store from'./store';
import VuevideoPlayer from "vue-video-player";
import "video.js/dist/video-js.css";
import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import Vant from "vant";
import { Lazyload } from 'vant';
import "vant/lib/index.css";
import baseApi from "@/api";
import BigNumber from "bignumber.js";

import init from "@/common/init.js";
import erc from "@/wallet/erc.js";

// 加载day.js初始化配置
// import dayjsFormat from './utils/day'
// Vue.prototype.$dayjsFormat = dayjsFormat;

// Vue.filter('relativeTime', value => {
//   return dayjs().to(dayjs(value))
// })

// import "uno.css";
Vue.use(Lazyload);

Vue.prototype.$init = init;
// Vue.prototype.$ws = ws;

Vue.prototype.i18n = i18n;

Vue.prototype.$BN = (val) => {
  return new BigNumber(val);
};
Vue.prototype.$wallet = {
  erc,
};

Vue.prototype.$http = baseApi;
import moment from "moment";
// 设置过滤器  规定时间格式
Vue.filter("formatDate", (value) => {
  return moment(value).format("YYYY-MM-DD HH:mm:ss");
});
// 时间戳转化
Vue.prototype.$moment = moment;
//引入并注册
import dateFormat from "@/utils/dateFormat";
Vue.use(dateFormat);
Vue.use(VuevideoPlayer);
Vue.use(VueAwesomeSwiper);
Vue.use(Vant);
Vue.config.productionTip = false;

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
