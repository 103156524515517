<!-- 借贷弹框 -->
<template>
  <van-popup class="loan-bomb" round v-model="dShow" @closed="handleClosed">
    <div class="font-m title">{{ $i18n.t("loan_congratulations") }}</div>
    <div class="loan-box">
      <div class="loan-tip theme-word-color">
        {{ $i18n.t("loan_tips1") }}<br />
        {{ $i18n.t("loan_tips2") }}<br />
        {{ $i18n.t("loan_tips3") }}
      </div>
      <div class="loan-item">
        <div class="name font-m">{{ $i18n.t("loan_submit_application") }}</div>
        <div class="word">{{ $i18n.t("loan_credit_points") }}</div>
      </div>
      <div class="loan-item">
        <div class="name font-m">{{ $i18n.t("loan_obtaining_loans") }}</div>
        <div class="word">{{ $i18n.t("loan_tasks") }}</div>
      </div>
      <div class="tip">{{ $i18n.t("loan_continuously1") }}</div>
      <div class="loan-data">
        <div class="data-title">{{ $i18n.t("loan_loanable_limit") }}</div>
        <div class="loan-data-box">
          <div class="data-box-item">
            <div class="word">{{ $i18n.t("loan_total1") }}</div>
            <div class="usdc theme-word-color font-m">{{ obj.loanAmount }} USDC</div>
          </div>
          <div class="data-box-item">
            <div class="word">{{ $i18n.t("loan_day2") }}</div>
            <div class="usdc theme-word-color font-m">
              {{ obj.loanDay }}{{ $i18n.t("loan_day2") }}
            </div>
          </div>
          <div class="data-box-item">
            <div class="word">{{ $i18n.t("loan_interest2") }}</div>
            <div class="usdc theme-word-color font-m">{{ obj.loanRate }} USDC</div>
          </div>
        </div>
      </div>
    </div>
    <van-button class="btn get-btn font-b" @click="handleApprove">
      {{ $i18n.t("loan_accept2") }}
    </van-button>
  </van-popup>
</template>

<script>
export default {
  props: {
    obj: {
      type: Object,
      default: () => {
        return {
          loanAmount: 0,
          loanDay: 0,
          loanRate: 0,
        };
      },
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dShow: false,
    }
  },
  watch: {
    show:function(a) {
      this.dShow = a
    }
  },
  methods: {
    async handleApprove() {
      const res = await this.$http.postUserLoanStatus();
      if (res.code === 0) {
        this.$toast(res.msg);
        this.$emit("close");
      }
    },
    handleClosed() {
      this.$emit("close");
    }
  },
};
</script>

<style lang="less" scoped>
.loan-bomb {
  width: 82vw;
  max-height: 70vh;
  min-height: 50vh;
  overflow: hidden;
  background: url("../assets/images/loan-bg.png");
  background-size: cover;
  display: flex;
  flex-direction: column;
  .loan-box {
    flex: 1;
    overflow-y: auto;
  }
  .title {
    font-size: 28px;
    color: #333;
    padding: 20px 0;
    margin-bottom: 24px;
  }
  .loan-tip {
    padding: 0 15px;
    font-size: 14px;
    margin-bottom: 16px;
  }
  .loan-box {
    padding: 0 15px;
    .loan-item {
      text-align: left;
      padding: 8px 15px;
      box-shadow: inset 0px 2px 4px 0px rgba(26, 67, 70, 0.04),
        inset 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
      border-radius: 0.32rem;
      margin-top: 15px;
      .name {
        color: #333;
        font-size: 14px;
        line-height: 21px;
      }
      .word {
        font-size: 12px;
        color: #999;
        line-height: 18px;
      }
    }
    .tip {
      font-size: 12px;
      color: #999;
      line-height: 18px;
      text-align: center;
      margin-bottom: 15px;
      margin-top: 10px;
    }
    .loan-data {
      .data-title {
        color: #333;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        margin-bottom: 5px;
      }
      .loan-data-box {
        display: flex;
        margin-bottom: 20px;
        .data-box-item {
          width: 33.3%;
          .word {
            color: #999;
            font-size: 14px;
            line-height: 21px;
          }
          .usdc {
            line-height: 21px;
          }
        }
      }
    }
  }
  .get-btn {
    width: 100% !important;
  }
}
</style>
