import Cookies from "js-cookie";
import store from'../store';

export const TOKEN_KEY = "token";
export const CODE_KEY = "code_key";
export const SOURCE_KEY = "source_key";

export const setToken = (token, cookieExpires) => {
  Cookies.set(TOKEN_KEY, token, {expires: cookieExpires || 30});
};

export const getToken = () => {
  const token = Cookies.get(TOKEN_KEY) || store.state.user.token;
  if (token) return token;
  else return false;
};

export const delToken = () => {
  Cookies.remove(TOKEN_KEY);
};

export const setInviteCode = (code, cookieExpires) => {
  Cookies.set(CODE_KEY, code, {expires: cookieExpires || 30});
};

export const getInviteCode = () => {
  const code = Cookies.get(CODE_KEY);
  if (code) return code;
  else return false;
};

export const setSource = (source, cookieExpires) => {
  Cookies.set(SOURCE_KEY, source, {expires: cookieExpires || 30});
};

export const getSource = () => {
  const source = Cookies.get(SOURCE_KEY);
  if (source) return source;
  else return false;
};
