import Vue from "vue";
import VueRouter from "vue-router";
import {getToken} from "../common/token";
import Home from "../views/Home.vue";

const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error=> error)
}

Vue.use(VueRouter);

const routes = [
  {
    path: "/index",
    name: "Home",
    component: Home,
  },
  {
    path: "/",
    name: "Home1",
    component: Home,
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/login-page",
    name: "LoginPage",
    component: () => import("../views/LoginPage.vue"),
  },
  {
    // 房间
    path: "/room",
    name: "Room",
    component: () => import("../views/Room.vue"),
  },
  {
    // 矿机
    path: "/mining",
    name: "Mining",
    component: () => import("../views/Mining.vue"),
  },
  {
    // 机器人
    path: "/robot",
    name: "Robot",
    component: () => import("../views/RobotPage.vue"),
  },
  {
    // 钱包
    path: "/wallet",
    name: "Wallet",
    component: () => import("../views/WalletPage.vue"),
  },
  {
    // 我的
    path: "/mine",
    name: "Mine",
    component: () => import("../views/Mine.vue"),
  },
  {
    // 充值
    path: "/recharge",
    name: "Recharge",
    component: () => import("../views/RechargePage.vue"),
  },
  {
    // 兑换
    path: "/exchange",
    name: "Exchange",
    component: () => import("../views/ExchangePage.vue"),
  },
  {
    // 提现
    path: "/withdraw",
    name: "Withdraw",
    component: () => import("../views/WithdrawPage.vue"),
  },
  {
    // 流水记录
    path: "/record",
    name: "Record",
    component: () => import("../views/RecordList.vue"),
  },
  {
    // 房间-藏品
    path: "/room-detail",
    name: "RoomDetail",
    component: () => import("../views/RoomDetail.vue"),
  },
  {
    // 藏品-详情
    path: "/goods-detail",
    name: "GoodsDetail",
    component: () => import("../views/GoodsDetail.vue"),
  },
  {
    // 邀请好友
    path: "/invate",
    name: "InvatePage",
    component: () => import("../views/InvatePage.vue"),
  },
  {
    // 借贷中心
    path: "/loan",
    name: "LoanCenter",
    component: () => import("../views/LoanCenter.vue"),
  },
  {
    // 借贷记录
    path: "/loan-record",
    name: "LoanRecord",
    component: () => import("../views/LoanRecord.vue"),
  },
  {
    // 订单记录
    path: "/order",
    name: "OrderList",
    component: () => import("../views/OrderList.vue"),
  },
  {
    // 订单详情
    path: "/order-info",
    name: "OrderInfo",
    component: () => import("../views/OrderDetail.vue"),
  },
  {
    // 客服
    path: "/kf",
    name: "KF",
    component: () => import("../views/ClienteleServices.vue"),
  },
  {
    // 系统消息
    path: "/message",
    name: "Message",
    component: () => import("../views/Message.vue"),
  },
  // 帮助中心
  {
    path: "/help",
    name: "Help",
    component: () => import("../views/HelpCenter.vue"),
  },
  // 隐私权
  {
    path: "/privacy",
    name: "privacy",
    component: () => import("../views/PrivacyPolicy.vue"),
  },
  // 用户协议
  {
    path: "/agreement",
    name: "Agreement",
    component: () => import("../views/UserAgreement.vue"),
  },
  // 社区准则
  {
    path: "/community",
    name: "Community",
    component: () => import("../views/Community.vue"),
  },
  // 忘记密码
  {
    path: "/forget",
    name: "Forget",
    component: () => import("../views/Forget.vue"),
  },
  // 注册
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/Register.vue"),
  },
  // Dapp登录
  {
    path: "/dapp-login",
    name: "DappLogin",
    component: () => import("../views/DappLogin.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// 路由拦截器
router.beforeEach(async (to, from, next) => {
  // console.log(to, from, next);
  if (to.name !== "Login") {
    let token = getToken();
    // const isReload = localStorage.getItem("isReload");
    if (token) {
      if (window && !window.ethereum) {
        next("login");
      }
      next();
    } else {
      next("login");
    }
  }
  localStorage.removeItem("isReload");
  next();
});

export default router;
